/* 362ac6b7be2152b3fbf7a884b89794935bdbaedd
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteOneTimeChargeMutationVariables = Types.Exact<{
  charge_id: Types.Scalars['uuid'];
}>;


export type DeleteOneTimeChargeMutation = { __typename?: 'Mutation', delete_one_time_charge: boolean };


export const DeleteOneTimeChargeDocument = gql`
    mutation DeleteOneTimeCharge($charge_id: uuid!) {
  delete_one_time_charge(id: $charge_id)
}
    `;
export type DeleteOneTimeChargeMutationFn = Apollo.MutationFunction<DeleteOneTimeChargeMutation, DeleteOneTimeChargeMutationVariables>;

/**
 * __useDeleteOneTimeChargeMutation__
 *
 * To run a mutation, you first call `useDeleteOneTimeChargeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneTimeChargeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneTimeChargeMutation, { data, loading, error }] = useDeleteOneTimeChargeMutation({
 *   variables: {
 *      charge_id: // value for 'charge_id'
 *   },
 * });
 */
export function useDeleteOneTimeChargeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOneTimeChargeMutation, DeleteOneTimeChargeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOneTimeChargeMutation, DeleteOneTimeChargeMutationVariables>(DeleteOneTimeChargeDocument, options);
      }
export type DeleteOneTimeChargeMutationHookResult = ReturnType<typeof useDeleteOneTimeChargeMutation>;
export type DeleteOneTimeChargeMutationResult = Apollo.MutationResult<DeleteOneTimeChargeMutation>;
export type DeleteOneTimeChargeMutationOptions = Apollo.BaseMutationOptions<DeleteOneTimeChargeMutation, DeleteOneTimeChargeMutationVariables>;