import { z } from "zod";
import { toDayjs } from "lib/date";
import { v4 as uuid } from "uuid";
import { dayjs } from "lib/dayjs";
import { Refine } from "../Customer/Contracts/CreateAndEdit/Schema";
import {
  ConversionOperation,
  EnvironmentTypeEnum_Enum,
  RoundingMethod,
} from "types/generated-graphql/__types__";

const CreditType = z.object({
  id: z.string().uuid(),
  name: z.string(),
  client_id: z.string().uuid().or(z.null()),
  environment_type: z
    .enum([
      EnvironmentTypeEnum_Enum.Production,
      EnvironmentTypeEnum_Enum.Qa,
      EnvironmentTypeEnum_Enum.Sandbox,
      EnvironmentTypeEnum_Enum.Staging,
      EnvironmentTypeEnum_Enum.Uat,
    ])
    .or(z.null()),
});
export namespace PricingRefine {
  export function descriptionLength(
    val: string | undefined,
    ctx: z.RefinementCtx,
  ) {
    if (val && val.length > 1024) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Description cannot exceed 1024 characters",
        fatal: true,
      });
    }
  }
}
export namespace Schema {
  export const QuantityConversion = z.object({
    conversionFactor: z.number().positive(),
    name: z.string().max(128).optional(),
    operation: z.enum([
      ConversionOperation.Multiply,
      ConversionOperation.Divide,
    ]),
  });

  export const QuantityRounding = z.object({
    roundingMethod: z.enum([
      RoundingMethod.Ceiling,
      RoundingMethod.Floor,
      RoundingMethod.HalfUp,
    ]),
    decimalPlaces: z.number().nonnegative().int("Number must be an integer"),
  });

  export const FixedProductInput = z.object({
    type: z.literal("fixed"),
  });

  export const CompositeProductInput = z.object({
    type: z.literal("composite"),
    netSuiteOverageItemId: z.string().max(128).optional(),
    compositeProductIds: z.array(z.string().uuid()).optional(),
    compositeTags: z.array(z.string()).optional(),
    excludeFreeUsage: z.boolean().optional(),
  });

  export const UsageProductInput = z.object({
    type: z.literal("usage"),
    netSuiteOverageItemId: z.string().max(128).optional(),
    billableMetricId: z.string().uuid(),
    quantityConversion: QuantityConversion.optional(),
    quantityRounding: QuantityRounding.optional(),
    pricingGroupKey: z.array(z.string()).optional(),
    presentationGroupKey: z.array(z.string()).optional(),
  });

  export const SubscriptionProductInput = z.object({
    type: z.literal("subscription"),
    netSuiteOverageItemId: z.string().max(128).optional(),
  });

  export const ProServiceProductInput = z.object({
    type: z.literal("proService"),
    netSuiteOverageItemId: z.string().max(128).optional(),
  });

  export const CreateProductInput = z.object({
    type: z.enum(["composite", "fixed", "usage", "subscription", "proService"]),
    product: z.union([
      FixedProductInput,
      CompositeProductInput,
      UsageProductInput,
      SubscriptionProductInput,
      ProServiceProductInput,
    ]),
    name: z.string().min(1).max(128),
    tags: z.array(z.string()).optional(),
    isRefundable: z.boolean().default(true),
    netSuiteInternalItemId: z.string().max(128).optional(),
  });

  export const EditProductInput = z.object({
    startingAt: z
      .string()
      .datetime()
      .superRefine((val, ctx) => {
        const d = toDayjs(val);
        if (d.minute() !== 0 || d.second() !== 0 || d.millisecond() !== 0) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Time in datetime must be UTC midnight",
            fatal: true,
          });
        }
      })
      .describe(
        "Timestamp representing when the update should go into effect.",
      ),
    name: z.string().min(1).max(128).optional(),
    tags: z.array(z.string()).optional(),
    type: z.enum(["composite", "fixed", "usage", "subscription", "proService"]),
    isRefundable: z.boolean().default(true).optional(),
    netSuiteInternalItemId: z.string().max(128).optional(),
    netSuiteOverageItemId: z.string().max(128).optional(),
    billableMetricId: z.string().uuid().optional(),
    compositeProductIds: z.array(z.string().uuid()).optional(),
    compositeTags: z.array(z.string()).optional(),
    quantityConversion: QuantityConversion.optional(),
    excludeFreeUsage: z.boolean().optional(),
    quantityRounding: QuantityRounding.optional(),
  });
  export const FlatPrice = z.object({
    type: z.literal("flat"),
    price: z
      .number()
      .nonnegative()
      .optional()
      .superRefine((val, ctx) => {
        if (val === undefined) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Price must be filled out for each product",
          });
        }
      }),
  });

  export const SubscriptionPrice = z.object({
    type: z.literal("subscription"),
    price: z
      .number()
      .nonnegative()
      .optional()
      .superRefine((val, ctx) => {
        if (val === undefined) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Price must be filled out for each product",
          });
        }
      }),
    quantity: z.number().positive(),
    isProrated: z.boolean().default(true),
  });

  export const PercentagePrice = z.object({
    type: z.literal("percentage"),
    fraction: z
      .number()
      .nonnegative()
      .optional()
      .superRefine((val, ctx) => {
        if (val === undefined) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Price must be filled out for each product",
          });
        }
      }),
    useListPrices: z.boolean().default(false),
  });

  export const TierForm = z
    .object({
      lastUnit: z.number().nonnegative().optional(),
      unitPrice: z
        .number()
        .nonnegative()
        .optional()
        .superRefine((val, ctx) => {
          if (val === undefined) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: "Price must be filled out for each product",
            });
          }
        }),
      prevLastUnit: z.number().nonnegative().optional(),
      isLastTier: z.boolean(),
    })
    .superRefine((tier, ctx) => {
      if (
        tier.lastUnit !== undefined &&
        tier.prevLastUnit !== undefined &&
        tier.lastUnit <= tier.prevLastUnit
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Last unit must be greater than previous last unit",
          path: ["lastUnit"],
          fatal: true,
        });
      }
      if (!tier.isLastTier && tier.lastUnit === undefined) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Only the last tier can be unbounded",
          path: ["lastUnit"],
          fatal: true,
        });
      }
    });

  export const Tier = z.object({
    lastUnit: z.number().nonnegative().optional(),
    unitPrice: z
      .number()
      .nonnegative()
      .optional()
      .superRefine((val, ctx) => {
        if (val === undefined) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Price must be filled out for each product",
          });
        }
      }),
  });

  export const TieredPrice = z.object({
    type: z.literal("tiered"),
    tiers: z.array(Tier),
  });

  export const CustomPrice = z.object({
    type: z.literal("custom"),
  });

  const EDIT_ONLY_UUID = z
    .string()
    .uuid()
    .default(() => uuid());

  const START_OF_TODAY_UTC = dayjs.utc().startOf("day").toISOString();

  export const Rate = z.object({
    id: EDIT_ONLY_UUID,
    productId: z.string().uuid(),
    startingAt: z
      .string()
      .datetime()
      .superRefine(Refine.isUtcMidnight)
      .default(START_OF_TODAY_UTC)
      .describe("Start date"),
    endingBefore: z
      .string()
      .datetime()
      .superRefine(Refine.isUtcMidnight)
      .optional()
      .describe("End date"),
    entitled: z.enum(["enable", "disable"]),
    pricingModel: z.enum(["flat usage", "tiered usage"]).optional(),
    pricingGroupValues: z.record(z.string()).optional(),

    price: z.union([
      FlatPrice,
      PercentagePrice,
      SubscriptionPrice,
      TieredPrice,
      CustomPrice,
    ]),
    creditType: CreditType.optional(),
    hasCommitRate: z.boolean().default(false).optional(),
    commitPricingModel: z.enum(["flat usage", "tiered usage"]).optional(),
    commitPrice: z
      .union([
        FlatPrice,
        PercentagePrice,
        SubscriptionPrice,
        TieredPrice,
        CustomPrice,
      ])
      .optional(),
    isOverrideRate: z.boolean().default(false).optional(),
    isRemovedSubrate: z.boolean().default(false).optional(),
    isCommitPrice: z.boolean().default(false).optional(),
    billingFrequency: z.string().optional(),
  });
  export const AddRateInput = z.object({
    rateCardId: z.string().uuid(),
    rates: z.array(Rate),
  });

  export const RateCardAlias = z.object({
    name: z.string().min(1).max(128),
    startingAt: z
      .string()
      .datetime()
      .superRefine(Refine.isUtcMidnight)
      .optional()
      .describe("Start date"),
    endingBefore: z
      .string()
      .datetime()
      .superRefine(Refine.isUtcMidnight)
      .optional()
      .describe("End date"),
  });

  export const CreditTypeConversion = z.object({
    custom_credit_type_id: z.string().uuid(),
    custom_credit_type_name: z.string(),
    fiat_per_custom_credit: z.number().nonnegative(),
  });

  export const RateCardInput = z.object({
    name: z.string().min(1).max(128),
    description: z
      .string()
      .optional()
      .superRefine(PricingRefine.descriptionLength),
    rates: z.array(Rate),
    aliases: z.array(RateCardAlias).optional(),
    fiatCreditTypeId: z.string().uuid(),
    fiatCreditTypeName: z.string().optional(),
    creditTypeConversions: z.array(CreditTypeConversion).optional(),
  });

  export const UnifiedRateCardInput = z.object({
    name: z.string().min(1).max(128),
    description: z
      .string()
      .optional()
      .superRefine(PricingRefine.descriptionLength),
    aliases: z.array(RateCardAlias).optional(),
    fiatCreditTypeId: z.string().uuid(),
    fiatCreditTypeName: z.string().optional(),
    creditTypeConversions: z.array(CreditTypeConversion).optional(),
    usageRates: z.array(Rate),
    subscriptionRates: z.array(Rate).optional(),
    compositeRates: z.array(Rate).optional(),
    selectedProducts: z.array(z.string().uuid()),
    dimensionalProducts: z.array(
      z.object({
        id: z.string().uuid(),
        name: z.string(),
        pricingGroupKeyValues: z.array(
          z.object({
            key: z.string(),
            values: z.array(z.string()),
          }),
        ),
      }),
    ),
  });

  export namespace Types {
    export type CreateProductInput = z.infer<typeof Schema.CreateProductInput>;
    export type CreateCompositeProductInput = z.infer<
      typeof Schema.CompositeProductInput
    >;
    export type CreateUsageProductInput = z.infer<
      typeof Schema.UsageProductInput
    >;
    export type SubscriptionProductInput = z.infer<
      typeof Schema.SubscriptionProductInput
    >;
    export type ProServiceProductInput = z.infer<typeof ProServiceProductInput>;
    export type AddRateInput = z.infer<typeof Schema.AddRateInput>;
    export type Rate = z.infer<typeof Schema.Rate>;
    export type RateCardInput = z.infer<typeof RateCardInput>;
    export type UnifiedRateCardInput = z.infer<typeof UnifiedRateCardInput>;
    export type RateCardAlias = z.infer<typeof RateCardAlias>;
    export type Tier = z.infer<typeof Tier>;
    export type QuantityConversion = z.infer<typeof QuantityConversion>;
    export type QuantityRounding = z.infer<typeof QuantityRounding>;
    export type CreditTypeConversion = z.infer<typeof CreditTypeConversion>;
  }
}
