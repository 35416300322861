import React from "react";

import { SubLineItemFieldsFragment } from "../fragments.graphql";

import Decimal from "decimal.js";
import {
  RoundedCurrency,
  UnroundedCurrency,
  USD_CREDIT_ID,
} from "app/lib/credits";
import {
  RowSpec,
  DeprecatedSimpleTable,
} from "components/deprecated/SimpleTable";
import { TierChargeLineItem } from "types/generated-graphql/__types__";
import { DeprecatedDeleteOneTimeChargeButton } from "components/deprecated/Invoice/components/DeleteOneTimeChargeButton";
import { Quantity } from "components/Quantity";
import { CreditType } from "app/types/credit-types";
import { EmbeddableDashboardContext } from "embeddable-dashboards/lib/embeddableDashboardContext";

export const DeprecatedSubLineItems: React.FC<{
  subLineItems: SubLineItemFieldsFragment[];
  invoiceId: string;
}> = ({ invoiceId, subLineItems }) => {
  return (
    <DeprecatedSubLineItemsTable
      invoiceId={invoiceId}
      rowSpecs={subLineItems.map((subLineItem) => ({
        type: "data",
        data: subLineItem,
      }))}
    />
  );
};

export const DeprecatedSubLineItemsTable: React.FC<{
  rowSpecs: RowSpec<SubLineItemFieldsFragment>[];
  invoiceId: string;
}> = ({ invoiceId, rowSpecs }) => {
  const { isEmbeddableDashboard } = EmbeddableDashboardContext.useContainer();

  return (
    <DeprecatedSimpleTable
      noBottomBorder
      rowSpecs={rowSpecs}
      columns={[
        {
          header: "Description",
          render: (subLineItem) => {
            if (
              (subLineItem.__typename === "ChargeLineItem" ||
                subLineItem.__typename === "AdvanceChargeLineItem") &&
              subLineItem.one_time_charge?.id
            ) {
              return (
                <>
                  {subLineItem.display_name}
                  <DeprecatedDeleteOneTimeChargeButton
                    invoiceId={invoiceId}
                    id={subLineItem.one_time_charge.id}
                  />
                </>
              );
            }
            return subLineItem.display_name;
          },
        },
        {
          header: "Quantity",
          alignment: "right",
          render: (subLineItem) => {
            if (
              subLineItem.__typename === "ChargeLineItem" ||
              subLineItem.__typename === "AdvanceChargeLineItem" ||
              subLineItem.__typename === "SeatChargeLineItem" ||
              subLineItem.__typename === "CompositeChargeLineItem"
            ) {
              return <Quantity quantity={subLineItem.quantity} />;
            } else if (tierChargeLineItemTypeGuard(subLineItem)) {
              return (
                <Quantity
                  quantity={(subLineItem as TierChargeLineItem).quantity}
                />
              );
            }
            return "-";
          },
        },
        {
          header: "Unit Price",
          alignment: "right",
          render: (subLineItem) => {
            const renderCurrencyComponent = (
              amount: Decimal,
              creditType: CreditType,
            ) =>
              isEmbeddableDashboard ? (
                <UnroundedCurrency amount={amount} creditType={creditType} />
              ) : (
                <RoundedCurrency amount={amount} creditType={creditType} />
              );
            if (
              subLineItem.__typename === "ChargeLineItem" ||
              subLineItem.__typename === "AdvanceChargeLineItem"
            ) {
              if (!subLineItem.unit_price) {
                return "Tiered";
              }
              const amount = new Decimal(subLineItem.unit_price);
              return renderCurrencyComponent(amount, subLineItem.credit_type);
            } else if (subLineItem.__typename === "SeatChargeLineItem") {
              const amount = new Decimal(subLineItem.seat_price);
              return renderCurrencyComponent(amount, subLineItem.credit_type);
            } else if (subLineItem.__typename === "CompositeChargeLineItem") {
              const unitPrice =
                subLineItem.quantity === "0"
                  ? new Decimal(0)
                  : new Decimal(subLineItem.total).div(subLineItem.quantity);
              return renderCurrencyComponent(
                unitPrice,
                subLineItem.credit_type,
              );
            } else if (tierChargeLineItemTypeGuard(subLineItem)) {
              const amount = new Decimal(subLineItem.tier_price);
              return renderCurrencyComponent(amount, subLineItem.credit_type);
            }
            return "-";
          },
        },
        {
          header: "Total",
          alignment: "right",
          render: (subLineItem) => {
            return (
              <RoundedCurrency
                amount={new Decimal(subLineItem.total)}
                creditType={subLineItem.credit_type}
                hideSuffix={subLineItem.credit_type.id === USD_CREDIT_ID}
              />
            );
          },
        },
      ]}
    />
  );
};

function tierChargeLineItemTypeGuard(
  t: SubLineItemFieldsFragment,
): t is TierChargeLineItem {
  return t.__typename === "TierChargeLineItem";
}
