import React from "react";
import classNames from "classnames";
import { Headline } from "design-system";
import { DeprecatedProductContainer } from "components/deprecated/ProductContainer";
import { Product } from "types/generated-graphql/__types__";
import { DeprecatedNoSubLineItems } from "components/deprecated/Invoice/components/NoSubLineItems";
import { GroupedProductChargeLineItemFragment } from "components/deprecated/Invoice/lib/groupedProductChargeLineItemUtils";
import { DeprecatedSubLineItemsTable } from "components/deprecated/Invoice/components/LineItem/lineItems/SubLineItems";
import { SubLineItemFieldsFragment } from "components/deprecated/Invoice/components/LineItem/fragments.graphql";
import { RowSpec } from "components/deprecated/SimpleTable";
import { EmbeddableDashboardContext } from "embeddable-dashboards/lib/embeddableDashboardContext";
import Decimal from "decimal.js";

/**
 * Render GroupedProductChargeLineItems in this hierarchy:
 *
 * - Product 1
 *   - Group 1
 *     - SubLineItem 1
 *     - SubLineItem 2
 *     - ...
 *   - Group 2
 *     - SubLineItem 1
 *     - SubLineItem 2
 *     - ...
 *   - ...
 */
export const DeprecatedGroupedProductChargeLineItems: React.FC<{
  invoiceId: string;
  product: Pick<Product, "id" | "name">;
  lineItems: GroupedProductChargeLineItemFragment[];
  showChargesWithZeroUsage: boolean;
}> = (props) => {
  const { getGroupDisplayName, getGroupValueDisplayName } =
    EmbeddableDashboardContext.useContainer();

  const groupKey =
    props.lineItems.length === 0 ? null : props.lineItems[0].group_key;

  const rowSpecs: RowSpec<SubLineItemFieldsFragment>[] = [];

  for (const lineItem of getLineItems(
    props.lineItems,
    props.showChargesWithZeroUsage,
  )) {
    switch (lineItem.__typename) {
      case "GroupedProductChargeLineItem":
        const groupKey = getGroupDisplayName(lineItem.group_key);
        const groupValue = getGroupValueDisplayName(
          lineItem.group_key,
          lineItem.group_value,
        );

        rowSpecs.push({
          type: "full-width-heading",
          text: `${groupKey}: "${groupValue}"`,
        });
        break;
      case "TierChargeLineItem":
        rowSpecs.push({
          type: "data",
          data: lineItem,
          subRow: true,
        });
        break;
      default:
        rowSpecs.push({
          type: "data",
          data: lineItem,
        });
        break;
    }
  }

  const { isEmbeddableDashboard } = EmbeddableDashboardContext.useContainer();
  return (
    <div className="p-12">
      <DeprecatedProductContainer
        title={
          <Headline
            level={5}
            className={
              isEmbeddableDashboard
                ? "text-default-font"
                : "text-deprecated-primary-dark"
            }
          >
            {props.product.name}
          </Headline>
        }
        groupKey={groupKey}
      >
        <div className="-mb-12 p-12 pb-0">
          {rowSpecs.length > 0 ? (
            <DeprecatedSubLineItemsTable
              invoiceId={props.invoiceId}
              rowSpecs={rowSpecs}
            />
          ) : (
            <DeprecatedNoSubLineItems
              showChargesWithZeroUsage={props.showChargesWithZeroUsage}
            />
          )}
        </div>
      </DeprecatedProductContainer>
    </div>
  );
};

export const DeprecatedLineItemGroupHeading: React.FC<{
  groupKey: string;
  groupValue: string | null;
  bgColor?: "grey";
}> = (props) => {
  return (
    <div
      className={classNames(
        "-mb-[6px] border-b border-solid border-deprecated-gray-100 px-12 py-8 font-mono text-xs",
        { "bg-gray-50": props.bgColor === "grey" },
      )}
    >
      {props.groupKey}: <b className="font-medium">"{props.groupValue}"</b>
    </div>
  );
};

/**
 * Infer type of all line items and sub line items.
 */
type FlattenLineItems<T> = T extends {
  sub_line_items?: Array<infer U> | null | undefined;
}
  ? T | FlattenLineItems<U>
  : T;

/**
 * Recursively DFS's all line items and their sub line items and accounts for
 * showChargesWithZeroUsage filter.
 */
function getLineItems(
  lineItems: FlattenLineItems<GroupedProductChargeLineItemFragment>[],
  showChargesWithZeroUsage: boolean,
): Array<FlattenLineItems<GroupedProductChargeLineItemFragment>> {
  let finalLineItems: Array<
    FlattenLineItems<GroupedProductChargeLineItemFragment>
  > = [];
  for (const lineItem of lineItems) {
    if (
      "sub_line_items" in lineItem &&
      lineItem.sub_line_items &&
      lineItem.sub_line_items.length > 0
    ) {
      const subLineItems = getLineItems(
        lineItem.sub_line_items ?? [],
        showChargesWithZeroUsage,
      );
      // Only show group key and value if there are sub line items that
      // are displayed (not filtered out by showChargesWithZeroUsage)
      if (subLineItems.length > 0) {
        finalLineItems.push(lineItem);
        finalLineItems.push(...subLineItems);
        continue;
      }
    } else if (
      showChargesWithZeroUsage ||
      !("quantity" in lineItem && new Decimal(lineItem.quantity).isZero())
    ) {
      finalLineItems.push(lineItem);
    }
  }
  return finalLineItems;
}
